import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { LoginService } from './login.service';
import { formatDate } from '@angular/common';

@Injectable({
  providedIn: 'root' 
})
export class VisitService {
  private httpHeaders = new HttpHeaders()
  //.set('Access-Control-Allow-Headers','Origin, X-Requested-With, Content-Type, Accept, Authorization')
    .set('Authorization','bearer '+localStorage.getItem("MedlUToken"))

  private environment;
  private url;

  //url: string = 'http://localhost:3333';
  //url: string = 'http://213.186.160.67/CareNet/MRZAPI';
  //url: string = 'http://192.168.127.41:222';
  
  email: string|null='';
  constructor(private httpClient: HttpClient,private loginService:LoginService, ) {
    this.loginService.username.subscribe(u=>{
      this.email=u
    })

    this.environment = environment;
    this.url = this.environment.url;
   }

  getLabsOrdersByMemberId(memberId:number){
    return this.httpClient.get<any>(`${this.url}/api/Lab/GetLabOrdersByMemberId?MemberId=${memberId}`,{headers:this.httpHeaders})
  }

  getLabsOrdersByMemberIdWithVisitNo(memberId: number, visitNo: any, referral: any, dates: any, status: any) {

  
    let fromDate = '';
    let toDate = '';

    if (dates && dates.length > 0) {
      fromDate = formatDate(dates[0], 'dd/MM/yyyy', 'en-US').toString();
      toDate = formatDate(dates[1], 'dd/MM/yyyy', 'en-US').toString();
    }

    let url = `${this.url}/api/Lab/GetLabOrdersByMemberId?MemberId=${memberId}&visitNo=${visitNo}&referral=${referral}&fromDate=${fromDate}&toDate=${toDate}&status=${status}`;
    return this.httpClient.get<any>(url, { headers: this.httpHeaders });
  }

// view PDF API (1) //

  // getLabTestsResultsForReferral(labOrderId:number,patientName:string){
  //   return this.httpClient.get<any>(`${this.url}/api/Reporting/GetLabTestsResultsForReferral?labOrderId=${labOrderId}&labTestsId=null&printedBy=${patientName}&showSignature=${environment.showSignature}&isArabicName=${environment.isArabicName}`
  //   ,{headers:this.httpHeaders})

 
  // }

// view PDF API (2) //
  getOnlineLabResults(labOrderId:number,patientName:string){

    return this.httpClient.get<any>(`${this.url}/api/Reporting/GetOnlineLabResults?labOrderId=${labOrderId}&printedBy=${patientName}`
    ,{headers:this.httpHeaders})
  }

  sendLabResultByNotificationEmail(labOrderId:number,patientName:string){
    return this.httpClient.get<any>(`${this.url}/api/Notification/SendLabResultByNotificationEmail?labOrderId=${labOrderId}&labTestsId=null&printedBy=${patientName}&toEmail=${this.email}&contactId=0&branchId=1&companyId=1&isArabicName=${environment.isArabicName}&showSignature=${environment.showSignature}&createdByNotification=${patientName}&checkFinancialStatus=true`
    ,{headers:this.httpHeaders})
  }

  getLabTestByLabOrerId(labOrderId:number){
    return this.httpClient.get<any>(`${this.url}/api/Lab/GetLabTestByLabOrerId?orderId=${labOrderId}`
    ,{headers:this.httpHeaders})
  }

  GetOnlineLabResults(labOrderId:number,patientName:string,labTestsId:number){
    return this.httpClient.get<any>(`${this.url}/api/Reporting/GetOnlineLabResults?labOrderId=${labOrderId}&printedBy=${patientName}&labTestsId=${labTestsId}`
    ,{headers:this.httpHeaders})
  }

  getPreviousResults(labOrderId:number,labTestsId:number,memberId:number){
    return this.httpClient.get<any>(`${this.url}/api/lab/GetPreviousResults?orderId=${labOrderId}&labTestId=${labTestsId}&memberId=${memberId}`
    ,{headers:this.httpHeaders})
  }
  // api/lab/GetPreviousResults?orderId=&labTestId=&memberId=
  // api/Reporting/GetOnlineLabResults?labOrderId={0}&printedBy={1}&labTestsId={2}
  // api/Notification/SendLabResultByNotificationEmail?labOrderId={0}&labTestIds={1}&printedBy={2}&toEmail={3}&contactId={4}&branchId={5}&companyId={6}&isArabicName={7}&showSignature={8}&createdByNotification={9}
  // api/Reporting/GetLabTestsResultsForReferral?labOrderId=ID&labTestsId=null&printedBy=DocName&showSignature=true&isArabicName=false
}
