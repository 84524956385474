import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, pipe } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CommonService } from 'src/app/services/shared/common.service';
import { environment } from 'src/environments/environment';
import { TokenHandlerService } from './token-handler.service';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, ActivatedRoute} from "@angular/router";
@Injectable({
  providedIn: 'root'
})
export class AppInterceptorService implements HttpInterceptor {
  constructor(private commonService: CommonService, private tokenhandler: TokenHandlerService, private router: Router, private activatedRoute: ActivatedRoute) { }

  headerConfig = {};
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
    this.showLoader();
    
    if (!req.url.includes('Token')) {
     
      let token = localStorage.getItem(environment.storageToken);
      if (token && !this.tokenhandler.isTokenExpired()) {
        
        return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            this.hideLoader();
          }
        },
          (err: any) => {
            this.hideLoader();
          }));
      }
      else {
       
        if (this.router.url != '/login' && this.router.url != '/') {
          this.router.navigateByUrl('');
        } else {
          return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
              this.hideLoader();
            }
          },
            (err: any) => {
              this.hideLoader();
            }));
        }
        this.hideLoader();

      }
    }
    else {
    
      return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          this.hideLoader();
        }
      },
        (err: any) => {
          this.hideLoader();
        }));
    }
    
  }

  private showLoader(): void {     
    this.commonService.showOrHideSpinner(true);
  }

  private hideLoader(): void {
    this.commonService.showOrHideSpinner(false);    
  }

}
